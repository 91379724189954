import React from 'react';

import './contact.scss';
import IndexLayout from '@layouts/index';
import Footer from '@components/Footer';
import Header from '@components/Header';
import Container from '@components/Container';
import Typography from '@components/Typography';
import SalesIcon from '@page-components/contact/icons/sales.svg';
import HelpIcon from '@page-components/contact/icons/help.svg';
import ContactForm from '@page-components/contact/ContactForm';
import ContactCard from '@page-components/contact/ContactCard';
import Button from '@components/Button';
import ContactCommunity from '@page-components/contact/ContactCommunity';
import links from '@constants/links';

const Contact: React.FC = () => {
  return (
    <IndexLayout
      pageName="contact"
      helmet={{
        title: 'Contact Us - TileDB',
        description:
          'From answering your questions about TileDB products, to requesting a customized demo, it is easy to get in touch. Meet the team behind the magic.',
      }}
    >
      <Header />
      <main className="main">
        <Container>
          <div className="page--contact__heading">
            <Typography as="h1" fontSize="heading-2" color="neutral-900" fontWeight="bold">
              Contact us
            </Typography>
          </div>
          <div className="page--contact__contact-cards">
            <ContactCard text="Talk to sales" image={SalesIcon} link={links.contactSales} />
            <ContactCard text="Get support" image={HelpIcon} link={links.support} target="_blank" />
          </div>
          <div className="page--contact__request-demo">
            <Typography className="page--contact__request-demo__Title" fontSize="subheading-1" fontWeight="semi-bold" color="neutral-main">
              Want to see TileDB Cloud in action?
            </Typography>
            <div className="page--contact__request-demo__buttons">
              <Button primary to={links.requestDemo}>
                Request a demo
              </Button>
              <Button outlined href={links.console.signup}>
                Sign up
              </Button>
            </div>
          </div>
          <ContactForm />
          <ContactCommunity />
        </Container>
      </main>
      <Footer />
    </IndexLayout>
  );
};

export default Contact;
